/* eslint-disable camelcase */
import React from 'react';
import { P302PB002GateSelfSupportingSecondPagePdf } from 'components/P302PB002/GateSelfSupportingResponse';
import { KNS_MAILBOX_YES } from 'components/Input/KnsMailbox';
import GeneralInfo from 'components/Response/GeneralInfo';
import PdfResponseComponent from './PdfResponseComponent';
import Comments from './Comments';
import { createFormTitle } from '../OrderForm/OrderFormHeader';
import GeneralComments from './GeneralComments';
import {
  MODEL_2D,
  MODEL_2DS,
  MODEL_3D_4, MODEL_3D_5,
  MODEL_EQ,
  MODEL_P302,
  MODEL_P302_PB002, MODEL_P302_PS005, MODEL_PB001,
  MODEL_PB003,
  MODEL_PB155,
  MODEL_PP001C,
  MODEL_PP001CC,
  MODEL_PP001N,
  MODEL_PP001P46,
  MODEL_PP001W,
  MODEL_PP002,
  MODEL_PP002DIVERSO,
  MODEL_PP002DIVERSO1,
  MODEL_PP002DIVERSO2,
  MODEL_PP002DIVERSO3,
  MODEL_PP002P,
  MODEL_PP002P102,
  MODEL_PP002P102_0,
  MODEL_PP002P64,
  MODEL_PP002P64V,
  MODEL_PP002P82,
  MODEL_PP002P82COLORE1,
  MODEL_PP002P82COLORE1_0,
  MODEL_PP002P82COLORE2,
  MODEL_PP002P82COLORE2_0,
  MODEL_PP002P82MIX,
  MODEL_PP002P82NOCE1,
  MODEL_PP002P82NOCE1_0,
  MODEL_PP002P82NOCE2,
  MODEL_PP002P82NOCE2_0,
  MODEL_PP002P82PINO1,
  MODEL_PP002P82PINO1_0,
  MODEL_PP002P82PINO2, MODEL_PP002P82PINO2_0, MODEL_PP002P82_0, MODEL_PS001, MODEL_PS002, MODEL_PS003,
  MODEL_PS004,
  MODEL_PS004_2,
  MODEL_SOLAR_PANEL_POLE,
  MODEL_STILE,
  MODEL_TABLE_SOLAR_PANEL, MODEL_TVORA2, MODEL_TVORA4, MODEL_TVORA5, MODEL_VERTICALE, MODEL_VERTICALE_LM,
} from '../../ModelsConstants';
import {
  FENCE_TYPE_FOLDING_GATE,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_GATE_GATEWAY, FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_NON_SYMMETRICAL_GATE, FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE, FENCE_TYPE_SINGLE_LEAF_GATE,
  FENCE_TYPE_SPAN,
} from '../../FenceTypesConstants';
// eslint-disable-next-line import/no-cycle
import { DiversoOneThreeGateSelfSupportingSecondPage } from '../PP002Diverso1/GateSelfSupportingResponse';
import {
  DiversoGateTiltingSecondPagePdf,
} from '../PP002Diverso/GateTiltingResponse';
import { PP001P46GateSelfSupporting5801SecondPage } from '../PP001P46/GateSelfSupportingResponse';
import { DiversoSingleLeafGateSecondPagePdf } from '../PP002Diverso/SingleLeafGateResponse';
import { PS004NonSymmetricalSecondPagePdf } from '../PS004/GateGatewayResponse';
import { PS004bNonSymmetricalSecondPagePdf } from '../PS004_2/GateGatewayResponse';
import { PS004bGateTilting5999SecondPagePdf } from '../PS004_2/GateTiltingResponse';
import { PS004bGateSelfSupporting5800SecondPagePdf } from '../PS004_2/GateSelfSupportingResponse';
import { PS004bSingleLeaf3000SecondPagePdf } from '../PS004_2/SingleLeafGateResponse';
import { PS004bSpanSecondPagePdf } from '../PS004_2/SpanResponse';
import { PS004SingleLeaf3000SecondPagePdf } from '../PS004/SingleLeafGateResponse';
import { PS004GateSelfSupportingSecondPagePdf } from '../PS004/GateSelfSupportingResponse';
// import { P82GateSelfSupportingSecondPage } from '../PP002P82/GateSelfSupportingResponse';
import { MixGateSelfSupportingSecondPage } from '../PP002P82Mix/GateSelfSupportingResponse';
import { Laminat1GateSelfSupportingSecondPage } from '../PP002P82Colore/GateSelfSupportingResponse';
import { Laminat2GateSelfSupportingSecondPage } from '../PP002P82Colore2/GateSelfSupportingResponse';
import { DiversoGateSelfSupportingSecondPage } from '../PP002Diverso/GateSelfSupportingResponse';
import { P102GateSelfSupportingSecondPage } from '../PP002P102/GateSelfSupportingResponse';
// import { P82GateTiltingL6000SecondPagePdf, P82GateTiltingSecondPagePdf } from '../PP002P82/GateTiltingResponse';
import { COLOR_HDG } from '../OrderForm/OrderFormFenceColor';
import { ThreeD4GateSelfSupportingSecondPagePdf } from '../ThreeD4/GateSelfSupportingResponse';
import { ThreeD5GateSelfSupportingSecondPagePdf } from '../ThreeD5/GateSelfSupportingResponse';
import { Ps003GateSelfSupportingSecondPagePdf } from '../PS003/GateSelfSupportingResponse';
import { Pb001GateSelfSupporting5800SecondPagePdf } from '../PB001/GateSelfSupportingResponse';
import { Pb003GateSelfSupporting5800SecondPagePdf } from '../PB003/GateSelfSupportingResponse';
import { PP002P82Laminat10GateSelfSupporting5801SecondPage } from '../PP002P82Colore_0/GateSelfSupportingResponse';
import { PP002P82Laminat20GateSelfSupporting5801SecondPage } from '../PP002P82Colore2_0/GateSelfSupportingResponse';
import { PP002P1020GateSelfSupporting5801SecondPage } from '../PP002P102_0/GateSelfSupportingResponse';
import { PS004bGatewaySecondPagePdf } from '../PS004_2/GatewayResponse';
import { PP001NGateSelfSupportingSecondPage } from '../PP001N/GateSelfSupportingResponse';
import { PinoGateGatewaySecondPage } from '../PP002P82Pino/GateGatewayResponse';
import { PP002P1020GateTiltingSecondPage } from '../PP002P102_0/GateTiltingResponse';
import { PP002P1020SingleLeafGateSecondPage } from '../PP002P102_0/SingleLeafGateResponse';
import { PP002P1020NonSymmetricalGateSecondPage } from '../PP002P102_0/GateGatewayResponse';
import { PP002P820GateSelfSupporting5801SecondPage } from '../PP002P82_0/GateSelfSupportingResponse';
import { PP002P820FoldingGateSecondPage } from '../PP002P82_0/FoldingGateResponse';
import { PP002P820NonSymmetricalGateSecondPage } from '../PP002P82_0/GateGatewayResponse';
import { PP002P820GateTiltingSecondPage } from '../PP002P82_0/GateTiltingResponse';
import { PP002P820SingleLeafGateSecondPage } from '../PP002P82_0/SingleLeafGateResponse';
import { DiversoSecondPagePdf } from '../PP002Diverso/GateGatewayResponse';
import { VerticaleLMGateSelfSupportingResponseSecondPage } from '../Verticale_LM/GateSelfSupportingResponse';
import { VerticaleLMGatewayResponseSecondPage } from '../Verticale_LM/GatewayResponse';
import { VerticaleLMGateTiltingResponseSecondPage } from '../Verticale_LM/GateTiltingResponse';
import { PP002GatewayResponseSecondPage } from '../PP002/GatewayResponse';
import { EntranceResponseSecondPage } from '../Eq/EntranceResponse';
import { P64VGatewayResponseSecondPage } from '../PP002P64V/GatewayResponse';
import { PP001CCGatewayResponseSecondPage } from '../PP001CC/GatewayResponse';
import { PP001CGatewayResponseSecondPage } from '../PP001C/GatewayResponse';
import { PP001NGatewayResponseSecondPage } from '../PP001N/GatewayResponse';
import { PP001WGatewayResponseSecondPage } from '../PP001W/GatewayResponse';
import { P46WGatewayResponseSecondPage } from '../PP001P46/GatewayResponse';
import { P64WGatewayResponseSecondPage } from '../PP002P64/GatewayResponse';
import { P82GatewayResponseSecondPage } from '../PP002P82/GatewayResponse';
import { P820GatewayResponseSecondPage } from '../PP002P82_0/GatewayResponse';
import { MIXGatewayResponseSecondPage } from '../PP002P82Mix/GatewayResponse';
import { P102GatewayResponseSecondPage } from '../PP002P102/GatewayResponse';
import { P1020GatewayResponseSecondPage } from '../PP002P102_0/GatewayResponse';
import { PP002PGatewayResponseSecondPage } from '../PP002P/GatewayResponse';
import { PS001GatewayResponseSecondPage } from '../PS001/GatewayResponse';
import { PS002GatewayResponseSecondPage } from '../PS002/GatewayResponse';
import { PS003GatewayResponseSecondPage } from '../PS003/GatewayResponse';
import { TVORA2GatewayResponseSecondPage } from '../Tvora2/GatewayResponse';
import { TVORA4GatewayResponseSecondPage } from '../Tvora4/GatewayResponse';
import { DIVGatewayResponseSecondPage } from '../PP002Diverso/GatewayResponse';
import { DIV1GatewayResponseSecondPage } from '../PP002Diverso1/GatewayResponse';
import { DIV2GatewayResponseSecondPage } from '../PP002Diverso2/GatewayResponse';
import { DIV3GatewayResponseSecondPage } from '../PP002Diverso3/GatewayResponse';
import { TWODGatewayResponseSecondPage } from '../TwoD/GatewayResponse';
import { TWODSGatewayResponseSecondPage } from '../TwoDS/GatewayResponse';
import { THREED4GatewayResponseSecondPage } from '../ThreeD4/GatewayResponse';
import { THREED5GatewayResponseSecondPage } from '../ThreeD5/GatewayResponse';
import { PB001GatewayResponseSecondPage } from '../PB001/GatewayResponse';
import { PB003GatewayResponseSecondPage } from '../PB003/GatewayResponse';
import { PB155GatewayResponseSecondPage } from '../PB155/GatewayResponse';
import { P302PB002GatewayResponseSecondPage } from '../P302PB002/GatewayResponse';
import { P302PS005GatewayResponseSecondPage } from '../P302PS005/GatewayResponse';
import { P302GatewayResponseSecondPage } from '../P302/GatewayResponse';
import { StileGatewayResponseSecondPage } from '../Stile/GatewayResponse';
import { P302FoldingGateSecondPage } from '../P302/FoldingGateResponse';
import { PP002GateTiltingResponseSecondPage } from '../PP002/GateTiltingResponse';
import { PP002PGateTiltingResponseSecondPage } from '../PP002P/GateTiltingResponse';

const Page = ({
  formsPerPage,
  orderNumber,
  pageNumber,
  pageNumberTotal,
  generalComment,
  isDuplicate,
  standardSignatures,
  nonStandardSignature,
  millSignature,
  plankSignature,
  studdingSignature,
  weldingSignature,
  kazimierzSignature,
  anglesSignature,
  railingSignature,
  storageSignature,
}) => {
  const forms = formsPerPage.map((form) => {
    const newerModelsWithPlank = [
      MODEL_PP002P82,
      MODEL_PP002P82_0,
      MODEL_PP002P82PINO1,
      MODEL_PP002P82PINO1_0,
      MODEL_PP002P82PINO2,
      MODEL_PP002P82PINO2_0,
      MODEL_PP002P82COLORE1,
      MODEL_PP002P82COLORE1_0,
      MODEL_PP002P82COLORE2,
      MODEL_PP002P82COLORE2_0,
      MODEL_PP002P82NOCE1,
      MODEL_PP002P82NOCE1_0,
      MODEL_PP002P82NOCE2,
      MODEL_PP002P82NOCE2_0,
      MODEL_PP002P82MIX,
      MODEL_PP002P102,
      MODEL_PP002P102_0,
      MODEL_PP002DIVERSO,
      MODEL_PP002DIVERSO1,
      MODEL_PP002DIVERSO2,
      MODEL_PP002DIVERSO3,
      MODEL_PP001C,
      MODEL_PP001CC,
      MODEL_PP001W,
      MODEL_PP002,
      MODEL_PP002P,
    ];

    const gatewaysOnTwoPages = {
      [MODEL_2D]: TWODGatewayResponseSecondPage,
      [MODEL_2DS]: TWODSGatewayResponseSecondPage,
      [MODEL_3D_4]: THREED4GatewayResponseSecondPage,
      [MODEL_3D_5]: THREED5GatewayResponseSecondPage,
      [MODEL_P302]: P302GatewayResponseSecondPage,
      [MODEL_P302_PB002]: P302PB002GatewayResponseSecondPage,
      [MODEL_P302_PS005]: P302PS005GatewayResponseSecondPage,
      [MODEL_PB001]: PB001GatewayResponseSecondPage,
      [MODEL_PB003]: PB003GatewayResponseSecondPage,
      [MODEL_PB155]: PB155GatewayResponseSecondPage,
      [MODEL_PP001C]: PP001CGatewayResponseSecondPage,
      [MODEL_PP001CC]: PP001CCGatewayResponseSecondPage,
      [MODEL_PP001N]: PP001NGatewayResponseSecondPage,
      [MODEL_PP001W]: PP001WGatewayResponseSecondPage,
      [MODEL_PP001P46]: P46WGatewayResponseSecondPage,
      [MODEL_PP002P64]: P64WGatewayResponseSecondPage,
      [MODEL_PP002P82]: P82GatewayResponseSecondPage,
      [MODEL_PP002P82COLORE1]: P82GatewayResponseSecondPage,
      [MODEL_PP002P82COLORE1_0]: P820GatewayResponseSecondPage,
      [MODEL_PP002P82COLORE2]: P82GatewayResponseSecondPage,
      [MODEL_PP002P82COLORE2_0]: P820GatewayResponseSecondPage,
      [MODEL_PP002P82PINO1]: P82GatewayResponseSecondPage,
      [MODEL_PP002P82PINO1_0]: P820GatewayResponseSecondPage,
      [MODEL_PP002P82PINO2]: P82GatewayResponseSecondPage,
      [MODEL_PP002P82PINO2_0]: P820GatewayResponseSecondPage,
      [MODEL_PP002P82NOCE1]: P82GatewayResponseSecondPage,
      [MODEL_PP002P82NOCE1_0]: P820GatewayResponseSecondPage,
      [MODEL_PP002P82NOCE2]: P82GatewayResponseSecondPage,
      [MODEL_PP002P82NOCE2_0]: P820GatewayResponseSecondPage,
      [MODEL_PP002P82MIX]: MIXGatewayResponseSecondPage,
      [MODEL_PP002P82_0]: P820GatewayResponseSecondPage,
      [MODEL_PP002P102]: P102GatewayResponseSecondPage,
      [MODEL_PP002P102_0]: P1020GatewayResponseSecondPage,
      [MODEL_PP002]: PP002GatewayResponseSecondPage,
      [MODEL_PP002P]: PP002PGatewayResponseSecondPage,
      [MODEL_PS001]: PS001GatewayResponseSecondPage,
      [MODEL_PS002]: PS002GatewayResponseSecondPage,
      [MODEL_PS003]: PS003GatewayResponseSecondPage,
      [MODEL_PS004_2]: PS004bGatewaySecondPagePdf,
      [MODEL_TVORA2]: TVORA2GatewayResponseSecondPage,
      [MODEL_TVORA4]: TVORA4GatewayResponseSecondPage,
      [MODEL_TVORA5]: TVORA4GatewayResponseSecondPage,
      [MODEL_VERTICALE]: VerticaleLMGatewayResponseSecondPage,
      [MODEL_PP002DIVERSO]: DIVGatewayResponseSecondPage,
      [MODEL_PP002DIVERSO1]: DIV1GatewayResponseSecondPage,
      [MODEL_PP002DIVERSO2]: DIV2GatewayResponseSecondPage,
      [MODEL_PP002DIVERSO3]: DIV3GatewayResponseSecondPage,
      [MODEL_STILE]: StileGatewayResponseSecondPage,
    };

    if (isDuplicate) {
      if (form.type === FENCE_TYPE_GATEWAY) {
        if (Object.prototype.hasOwnProperty.call(gatewaysOnTwoPages, form.model)) {
          const Comp = gatewaysOnTwoPages[form.model];
          return (
            <div key={form.id}>
              <Comp form={form} />
            </div>
          );
        }
      }
      if (form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE || form.type === FENCE_TYPE_GATE_GATEWAY) {
        // if (form.model === MODEL_PP002P82) {
        //   return (
        //     <div key={form.id}>
        //       <P82SecondPage form={form} />
        //     </div>
        //   );
        // }
        if (form.model === MODEL_PP002P82_0) {
          return (
            <div key={form.id}>
              <PP002P820NonSymmetricalGateSecondPage form={form} />
            </div>
          );
        } if (form.model === MODEL_PP002P82COLORE1 || form.model === MODEL_PP002P82 || form.model === MODEL_PP002P82PINO1 || form.model === MODEL_PP002P82NOCE1 || form.model === MODEL_PP002P82COLORE2 || form.model === MODEL_PP002P82NOCE2 || form.model === MODEL_PP002P82PINO2 || form.model === MODEL_PP002P102 || form.model === MODEL_PP002P82MIX) {
          return (
            <div key={form.id}>
              <PinoGateGatewaySecondPage form={form} />
            </div>
          );
        }
        // if (form.model === MODEL_PP002P82COLORE2 || form.model === MODEL_PP002P82PINO2 || form.model === MODEL_PP002P82NOCE2) {
        //   return (
        //     <div key={form.id}>
        //       <Laminat2SecondPage form={form} />
        //     </div>
        //   );
        // }
        // if (form.model === MODEL_PP002P82PINO1_0 || form.model === MODEL_PP002P82NOCE1_0 || form.model === MODEL_PP002P82COLORE1_0) {
        //   return (
        //     <div key={form.id}>
        //       <PP002P82Laminat10SecondPagePdf form={form} />
        //     </div>
        //   );
        // }
        // if (form.model === MODEL_PP002P82PINO2_0 || form.model === MODEL_PP002P82NOCE2_0 || form.model === MODEL_PP002P82COLORE2_0) {
        //   return (
        //     <div key={form.id}>
        //       <PP002P82Laminat20SecondPagePdf form={form} />
        //     </div>
        //   );
        // }
        // if (form.model === MODEL_PP002P102) {
        //   return (
        //     <div key={form.id}>
        //       <P102SecondPage form={form} />
        //     </div>
        //   );
        // }
        // if (form.model === MODEL_PP002P102_0) {
        //   return (
        //     <div key={form.id}>
        //       <PP002P1020SecondPagePdf form={form} />
        //     </div>
        //   );
        // }
        // if (form.model === MODEL_PP002P82MIX) {
        //   return (
        //     <div key={form.id}>
        //       <P82MixSecondPage form={form} />
        //     </div>
        //   );
        // }
        if (form.model === MODEL_PP002DIVERSO || form.model === MODEL_PP002DIVERSO1 || form.model === MODEL_PP002DIVERSO2 || form.model === MODEL_PP002DIVERSO3) {
          return (
            <div key={form.id}>
              <DiversoSecondPagePdf form={form} />
            </div>
          );
        }
        // if (form.model === MODEL_PP002DIVERSO1) {
        //   return (
        //     <div key={form.id}>
        //       <Diverso1SecondPagePdf form={form} />
        //     </div>
        //   );
        // }
        // if (form.model === MODEL_PP002DIVERSO2) {
        //   return (
        //     <div key={form.id}>
        //       <Diverso2GateGatewaySecondPagePdf form={form} />
        //     </div>
        //   );
        // }
        // if (form.model === MODEL_PP002DIVERSO3) {
        //   return (
        //     <div key={form.id}>
        //       <Diverso3GateGatewaySecondPagePdf form={form} />
        //     </div>
        //   );
        // }
        if (form.model === MODEL_PS004) {
          return (
            <div key={form.id}>
              <PS004NonSymmetricalSecondPagePdf response={form.response} isPdf />
            </div>
          );
        }
        if (form.model === MODEL_PS004_2) {
          return (
            <div key={form.id}>
              <PS004bNonSymmetricalSecondPagePdf values={form.values} response={form.response} isPdf />
            </div>
          );
        }
      } else if (form.model === MODEL_PP002DIVERSO) {
        if (form.type === FENCE_TYPE_SINGLE_LEAF_GATE || form.type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE || form.type === FENCE_TYPE_FOLDING_GATE) {
          return (
            <div key={form.id}>
              <DiversoSingleLeafGateSecondPagePdf form={form} />
            </div>
          );
        }
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <DiversoGateSelfSupportingSecondPage form={form} />
            </div>
          );
        }
      } else if (form.model === MODEL_PP002DIVERSO1) {
        if (form.type === FENCE_TYPE_GATE_TILTING) {
          return (
            <div key={form.id}>
              <DiversoGateTiltingSecondPagePdf form={form} />
            </div>
          );
        }
        if (form.type === FENCE_TYPE_SINGLE_LEAF_GATE || form.type === FENCE_TYPE_FOLDING_GATE || form.type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE) {
          return (
            <div key={form.id}>
              <DiversoSingleLeafGateSecondPagePdf form={form} />
            </div>
          );
        }
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <DiversoGateSelfSupportingSecondPage form={form} />
            </div>
          );
        }
      } else if (form.model === MODEL_PP002DIVERSO2) {
        if (form.type === FENCE_TYPE_GATE_TILTING) {
          return (
            <div key={form.id}>
              <DiversoGateTiltingSecondPagePdf form={form} />
            </div>
          );
        }
        if (form.type === FENCE_TYPE_SINGLE_LEAF_GATE || form.type === FENCE_TYPE_FOLDING_GATE || form.type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE) {
          return (
            <div key={form.id}>
              <DiversoSingleLeafGateSecondPagePdf form={form} />
            </div>
          );
        }
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <DiversoOneThreeGateSelfSupportingSecondPage form={form} />
            </div>
          );
        }
      } if (form.model === MODEL_PP002DIVERSO) {
        if (form.type === FENCE_TYPE_GATE_TILTING && form.values.width >= 6000) {
          return (
            <div key={form.id}>
              <DiversoGateTiltingSecondPagePdf form={form} />
            </div>
          );
        } if (form.type === FENCE_TYPE_GATE_TILTING && form.values.width < 6000) {
          return (
            <div key={form.id}>
              <DiversoGateTiltingSecondPagePdf form={form} />
            </div>
          );
        }
      }
      if (form.model === MODEL_PP002DIVERSO3) {
        if (form.type === FENCE_TYPE_GATE_TILTING) {
          return (
            <div key={form.id}>
              <DiversoGateTiltingSecondPagePdf form={form} />
            </div>
          );
        }
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <DiversoOneThreeGateSelfSupportingSecondPage form={form} />
            </div>
          );
        }
        if (form.type === FENCE_TYPE_SINGLE_LEAF_GATE || form.type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE || form.type === FENCE_TYPE_FOLDING_GATE) {
          return (
            <div key={form.id}>
              <DiversoSingleLeafGateSecondPagePdf form={form} />
            </div>
          );
        }
      }
      if (form.model === MODEL_PP002P82MIX) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <MixGateSelfSupportingSecondPage form={form} />
            </div>
          );
        }
      }
      if (form.model === MODEL_PP002P82COLORE1 || form.model === MODEL_PP002P82PINO1 || form.model === MODEL_PP002P82NOCE1) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <Laminat1GateSelfSupportingSecondPage form={form} />
            </div>
          );
        }
      } if (form.model === MODEL_PP002P82COLORE1_0 || form.model === MODEL_PP002P82NOCE1_0 || form.model === MODEL_PP002P82PINO1_0) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
          return (
            <div key={form.id}>
              <PP002P82Laminat10GateSelfSupporting5801SecondPage form={form} />
            </div>
          );
        }
      } if (form.model === MODEL_PP002P82COLORE2 || form.model === MODEL_PP002P82NOCE2 || form.model === MODEL_PP002P82PINO2) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <Laminat2GateSelfSupportingSecondPage form={form} />
            </div>
          );
        }
      } if (form.model === MODEL_PP002P82COLORE2_0 || form.model === MODEL_PP002P82NOCE2_0 || form.model === MODEL_PP002P82PINO2_0) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
          return (
            <div key={form.id}>
              <PP002P82Laminat20GateSelfSupporting5801SecondPage form={form} />
            </div>
          );
        }
      }
      if (form.model === MODEL_PP002P82) {
        // if (form.type === FENCE_TYPE_SINGLE_LEAF_GATE && (form.values.width >= 3000 || form.values.height >= 1000)) {
        //   return (
        //     <div key={form.id}>
        //       <P82SingleLeafGateSecondPagePdf form={form} />
        //     </div>
        //   );
        // }
        // if (form.type === FENCE_TYPE_FOLDING_GATE && form.values.height >= 1000) {
        //   return (
        //     <div key={form.id}>
        //       <P82FoldingGateSecondPagePdf form={form} />
        //     </div>
        //   );
        // }
        // if (form.type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE && form.values.height >= 1000) {
        //   return (
        //     <div key={form.id}>
        //       <P82SingleLeafFoldingGateSecondPagePdf form={form} />
        //     </div>
        //   );
        // }
        // if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
        //   return (
        //     <div key={form.id}>
        //       <P82GateSelfSupportingSecondPage form={form} />
        //     </div>
        //   );
        // }
      } if (form.model === MODEL_PP002P102) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <P102GateSelfSupportingSecondPage form={form} />
            </div>
          );
        }
      } if (form.model === MODEL_PP002P102_0) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
          return (
            <div key={form.id}>
              <PP002P1020GateSelfSupporting5801SecondPage form={form} />
            </div>
          );
        }
      } if (form.model === MODEL_3D_4) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <ThreeD4GateSelfSupportingSecondPagePdf values={form.values} response={form.response} />
            </div>
          );
        }
      } if (form.model === MODEL_3D_5) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <ThreeD5GateSelfSupportingSecondPagePdf values={form.values} response={form.response} />
            </div>
          );
        }
      } if (form.model === MODEL_PB001) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
          return (
            <div key={form.id}>
              <Pb001GateSelfSupporting5800SecondPagePdf values={form.values} response={form.response} />
            </div>
          );
        }
      } if (form.model === MODEL_PB003) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
          return (
            <div key={form.id}>
              <Pb003GateSelfSupporting5800SecondPagePdf values={form.values} response={form.response} />
            </div>
          );
        }
      } if (form.model === MODEL_PS003) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <Ps003GateSelfSupportingSecondPagePdf values={form.values} response={form.response} />
            </div>
          );
        }
      } if (form.model === MODEL_PS004) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
          return (
            <div key={form.id}>
              <PS004GateSelfSupportingSecondPagePdf values={form.values} response={form.response} isPdf />
            </div>
          );
        }
        if (form.type === FENCE_TYPE_SINGLE_LEAF_GATE && form.values.width >= 3000) {
          return (
            <div key={form.id}>
              <PS004SingleLeaf3000SecondPagePdf values={form.values} response={form.response} isPdf />
            </div>
          );
        }
      } if (form.model === MODEL_PS004_2) {
        if (form.type === FENCE_TYPE_SINGLE_LEAF_GATE && form.values.width >= 3000) {
          return (
            <div key={form.id}>
              <PS004bSingleLeaf3000SecondPagePdf values={form.values} response={form.response} isPdf />
            </div>
          );
        } if (form.type === FENCE_TYPE_GATE_TILTING && form.values.width >= 6000) {
          return (
            <div key={form.id}>
              <PS004bGateTilting5999SecondPagePdf values={form.values} response={form.response} isPdf />
            </div>
          );
        } if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
          return (
            <div key={form.id}>
              <PS004bGateSelfSupporting5800SecondPagePdf values={form.values} response={form.response} isPdf />
            </div>
          );
        } if (form.type === FENCE_TYPE_SPAN) {
          return (
            <div key={form.id}>
              <PS004bSpanSecondPagePdf />
            </div>
          );
        } if (form.type === FENCE_TYPE_GATEWAY && form.values.height >= 1400 && form.values.knsMailbox === KNS_MAILBOX_YES) {
          return (
            <div key={form.id}>
              <PS004bGatewaySecondPagePdf form={form} />
            </div>
          );
        }
      } if (form.model === MODEL_P302) {
        if (form.type === FENCE_TYPE_FOLDING_GATE) {
          return (
            <div key={form.id}>
              <P302FoldingGateSecondPage response={form.response} isPdf />
            </div>
          );
        }
      } if (form.model === MODEL_P302_PB002) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <P302PB002GateSelfSupportingSecondPagePdf values={form.values} response={form.response} isPdf />
            </div>
          );
        }
      } if (form.model === MODEL_PP001P46 && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width >= 5801) {
        return (
          <div key={form.id}>
            <PP001P46GateSelfSupporting5801SecondPage form={form} />
          </div>
        );
      } if (form.model === MODEL_PP002P102_0 && form.type === FENCE_TYPE_GATE_TILTING) {
        return (
          <div key={form.id}>
            <PP002P1020GateTiltingSecondPage form={form} />
          </div>
        );
      } if (form.model === MODEL_PP002P102_0 && form.type === FENCE_TYPE_SINGLE_LEAF_GATE) {
        return (
          <div key={form.id}>
            <PP002P1020SingleLeafGateSecondPage form={form} />
          </div>
        );
      } if (form.model === MODEL_PP002P102_0 && (form.type === FENCE_TYPE_GATE_GATEWAY || form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE)) {
        return (
          <div key={form.id}>
            <PP002P1020NonSymmetricalGateSecondPage form={form} />
          </div>
        );
      } if (form.model === MODEL_PP002P82_0 && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
        return (
          <div key={form.id}>
            <PP002P820GateSelfSupporting5801SecondPage form={form} />
          </div>
        );
      } if ((form.model === MODEL_PP002P82_0 || form.model === MODEL_PP002P102_0) && (form.type === FENCE_TYPE_FOLDING_GATE || form.type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE)) {
        return (
          <div key={form.id}>
            <PP002P820FoldingGateSecondPage form={form} />
          </div>
        );
      } if (form.model === MODEL_PP002P82_0 && form.type === FENCE_TYPE_GATE_TILTING) {
        return (
          <div key={form.id}>
            <PP002P820GateTiltingSecondPage form={form} />
          </div>
        );
      } if (form.model === MODEL_PP002P82_0 && form.type === FENCE_TYPE_SINGLE_LEAF_GATE) {
        return (
          <div key={form.id}>
            <PP002P820SingleLeafGateSecondPage form={form} />
          </div>
        );
      } if (form.model === MODEL_PP001N && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width >= 5801) {
        return (
          <div key={form.id}>
            <PP001NGateSelfSupportingSecondPage form={form} />
          </div>
        );
      } if (form.model === MODEL_VERTICALE_LM) {
        if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
          return (
            <div key={form.id}>
              <VerticaleLMGateSelfSupportingResponseSecondPage form={form} />
            </div>
          );
        }
        if (form.type === FENCE_TYPE_GATEWAY) {
          return (
            <div key={form.id}>
              <VerticaleLMGatewayResponseSecondPage form={form} />
            </div>
          );
        }
        if (form.type === FENCE_TYPE_GATE_TILTING) {
          return (
            <div key={form.id}>
              <VerticaleLMGateTiltingResponseSecondPage form={form} />
            </div>
          );
        }
      } if (form.model === MODEL_PP002) {
        if (form.type === FENCE_TYPE_GATEWAY) {
          return (
            <div key={form.id}>
              <PP002GatewayResponseSecondPage form={form} />
            </div>
          );
        }
        if (form.type === FENCE_TYPE_GATE_TILTING && form.values.width < 6000) {
          return (
            <div key={form.id}>
              <PP002GateTiltingResponseSecondPage form={form} />
            </div>
          );
        }
      } if (form.model === MODEL_PP002P) {
        if (form.type === FENCE_TYPE_GATE_TILTING && form.values.width < 6000) {
          return (
            <div key={form.id}>
              <PP002PGateTiltingResponseSecondPage form={form} />
            </div>
          );
        }
      } if (form.model === MODEL_EQ) {
        return (
          <div key={form.id}>
            <EntranceResponseSecondPage response={form.response} />
          </div>
        );
      } if (form.model === MODEL_PP002P64V) {
        if (form.type === FENCE_TYPE_GATEWAY) {
          return (
            <div key={form.id}>
              <P64VGatewayResponseSecondPage response={form.response} />
            </div>
          );
        }
      }
    }

    // TODO: podczas dodawania kolejnego formularza nie przepisuje się typ dla modeli bez typów
    let simpletag = `${form.model}`;
    if (form.type) {
      simpletag = `${form.model}_${form.type.toUpperCase()}`;
    } else {
      if (form.model === MODEL_TABLE_SOLAR_PANEL) {
        simpletag = `${form.model}_TABLE_SOLAR_PANEL`;
      }
      if (form.model === MODEL_SOLAR_PANEL_POLE) {
        simpletag = `${form.model}_SOLAR_PANEL_POLE`;
      }
    }
    const fulltag = simpletag;

    return (
      <div key={form.id}>
        <div>
          <p>
            NR PROD:
            {orderNumber}
          </p>
          <p>{createFormTitle(form)}</p>
        </div>
        {(newerModelsWithPlank.indexOf(form.model) > -1 && form.type !== FENCE_TYPE_SPAN)
          && <GeneralInfo form={form} />}
        <PdfResponseComponent
          tag={fulltag}
          response={form.response}
          values={form.values}
          hdg={form.color === COLOR_HDG}
        />
        <Comments form={form} />
      </div>
    );
  });

  const SawSignature = () => (
    <div>
      <p>Podpis piła:</p>
    </div>
  );

  const StandardSignatures = () => (
    <>
      <div>
        <p>Podpis 2D:</p>
      </div>
      <div>
        <p>Podpis 3D:</p>
      </div>
    </>
  );

  const NonStandardSignature = () => (
    <>
      <div>
        <p>Podpis piła:</p>
      </div>
      <div>
        <p>Podpis 2D:</p>
      </div>
    </>
  );

  const MillSignature = () => (
    <div>
      <p>Podpis walcarki:</p>
    </div>
  );

  const PlankSignature = () => (
    <div>
      <p>Podpis deska:</p>
    </div>
  );

  const StuddingSignature = () => (
    <div>
      <p>Podpis nabijanie:</p>
    </div>
  );

  const WeldingSignature = () => (
    <div>
      <p>Podpis spawalnia:</p>
    </div>
  );

  const KazimierzSignature = () => (
    <div>
      <p>Podpis Kazimierz:</p>
    </div>
  );

  const AnglesSignature = () => (
    <div>
      <p>Podpis kąty:</p>
    </div>
  );

  const RailingSignature = () => (
    <div>
      <p>Podpis sztachety:</p>
    </div>
  );

  const StorageSignature = () => (
    <div>
      <p>Podpis magazyn:</p>
    </div>
  );

  const Signature = () => (
    <>
      <div id="signature">
        {standardSignatures && studdingSignature && plankSignature && millSignature && <SawSignature />}
        {standardSignatures && <StandardSignatures />}
        {!standardSignatures && nonStandardSignature && <NonStandardSignature />}
        {anglesSignature && <AnglesSignature />}
      </div>
      <div id="signatureSecondColumn">
        {standardSignatures && (!millSignature || !studdingSignature || !plankSignature) && <SawSignature />}
        {millSignature && <MillSignature />}
        {studdingSignature && <StuddingSignature />}
        {plankSignature && <PlankSignature />}
        {weldingSignature && <WeldingSignature />}
        {kazimierzSignature && <KazimierzSignature />}
        {railingSignature && <RailingSignature />}
        {storageSignature && <StorageSignature />}
      </div>
    </>
  );

  return (
    <div className="new-page">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="new-page-inner">
              <div className="header">
                <h1>KARTA MATERIAŁOWA</h1>
              </div>
              {forms}
              {pageNumber === pageNumberTotal && <GeneralComments generalComment={generalComment} />}
              {pageNumber === pageNumberTotal && <Signature />}
              <div className="pageNumber">
                <span>
                  {pageNumber}
                  {' '}
                  /
                  {' '}
                  {pageNumberTotal}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
